import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
    selector: 'sis-button',
    template: `
        <button
            mat-raised-button
            *ngIf="isShown"
            [id]="id"
            [color]="color"
            [disabled]="disabled"
            (click)="clickEvent(); onClick.emit($event)"
        >
            {{ buttonMsg | sisTranslateMessage | async }}
        </button>
    `,
})
@ControlMetadata({
    template: `
        <sis-button
            [id]="'%{id}'"
            [color]="%{color}"
            (onClick)="%{onClick}"
            [buttonMsg]="%{button_msg}"
            [isShown]="%{isShown}">
        </sis-button>
    `,
})
export class SisButtonComponent {
    @Input()
    public isShown = true;
    @Input()
    public id: string;
    @Input()
    public color: string;
    @Output()
    public onClick: EventEmitter<object> = new EventEmitter<object>();
    @Input()
    public buttonMsg: string;

    public disabled = false;

    public clickEvent() {
        this.disabled = true;
        setTimeout(() => {
            this.disabled = false;
        }, 500);
    }
}
