import { Component, Input, OnInit } from '@angular/core';
import { ControlMetadata } from '@synisys/idm-dynamic-controls-metadata';

@Component({
    selector: 'sis-user',
    template: `
        <span id="{{id}}">{{value}}</span>
    `,
})

@ControlMetadata({
    template: `
        <sis-user [id]="%{id}" [value]="%{field}"></sis-user>
    `,
})

export class SisUserComponent implements OnInit {
    @Input()
    public id: string;

    @Input()
    public value: string;

    public ngOnInit() {
        this.value = 'User';
    }
}
