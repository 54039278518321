import {Component, Input} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
             selector: 'sis-simple-varying-text',
             template: `
               <div [attr.dir]="dir">{{value}}</div>
             `,
           })
@ControlMetadata({
                   template: `<sis-simple-varying-text [dir]="%{dir}" [value]="%{value}"></sis-simple-varying-text>`,
                 })
export class SisSimpleVaryingTextComponent {
  @Input()
  public value: string;

  @Input()
  public dir = 'ltr';
}
