import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
    Classifier,
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisRadioSettingsComponent} from './sis-radio-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import './sis-radio.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'sis-radio',
    templateUrl: 'sis-radio.component.html',
})
@ControlMetadata({
    cellCount: 3,
    groups: [{identity: 'input', name: 'Input Elements'}],
    iconInfo: 'fa-dot-circle-o',
    name: 'Radio Button',
    nameKey: 'de.controls.radio_button.title',
    settings: {
        main: SisRadioSettingsComponent,
    },
    template: `
                        <sis-radio [id]="'%{id}'"
                                   [items]="actions.loadClassifiers('%{checkedItem-category}') | async"
                                   [checked]="%{checkedItem}"
                                   (onClick)="contextEntity.getProperty('%{checkedItem-name}').value = $event"
                                   [isReadonly]="%{isReadonly}"
                                   [fieldCategory]="'%{checkedItem-category}'"
                                   [hint]="%{hint}"
                                   [disabled]="%{disabled}">
                        </sis-radio>
                        `,
})
export class SisRadioComponent extends SisControlWithSettings
    implements OnDestroy {
    @Input()
    public id: string;
    @Input()
    public items: ClassifierView[] = [];
    @Input()
    public fieldCategory: string;
    @Input()
    public disabled = false;
    @Output()
    public onClick: EventEmitter<Classifier> = new EventEmitter();
    @Input()
    public isReadonly = false;
    public checkedItem: Classifier | undefined;

    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(private readonly classifierService: ClassifierService) {
        super();
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    @Input()
    set checked(value: Classifier) {
        this.checkedItem = value;
    }

    public findItemById(): ClassifierView | undefined {
        if (!this.checkedItem) {
            return undefined;
        }
        return this.items.find(
            (item: ClassifierView) => item.getId() === this.checkedItem.getId()
        );
    }

    public getIsReadonly(): boolean {
        return this.isReadonly;
    }

    public emitClick(item: ClassifierView): void {
        if (!this.disabled) {
            this.classifierService
                .loadClassifier(this.fieldCategory, item.id)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(value => {
                    this.onClick.emit(value);
                    this.checkedItem = value;
                }, console.error);
        }
    }
}
