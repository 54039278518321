import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Entity} from '@synisys/idm-de-core-frontend';
import {SisCheckboxSettingsComponent} from './sis-checkbox-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import './sis-checkbox.component.scss';

@Component({
             moduleId : module.id + '',
             selector : 'sis-checkbox',
             templateUrl : 'sis-checkbox.component.html'
           })
@ControlMetadata({
                   cellCount: 1,
                   name     : 'Checkbox',
                   settings : {
                     main: SisCheckboxSettingsComponent,
                   },
                   template : `
                        <sis-checkbox
                            [id]="'%{id}'"
                            [items]="%{items}"
                            [checkedItems]="%{checkedItems}"
                            [isReadonly]="%{isReadonly}"
                            [hint]="%{hint}"
                            (onChange)="%{change}">
                        </sis-checkbox>
                        `,
                   isFieldBound: true,
                 })
export class SisCheckboxComponent extends SisControlWithSettings {
  @Input()
  public id: string;
  @Input()
  public items: Entity;
  @Input()
  public checkedItems: Entity[];
  @Input()
  public isReadonly = false;
  @Output()
  public onChange: EventEmitter<Entity> = new EventEmitter<Entity>();

  public isChecked(item: Entity): boolean {
    return this.checkedItems.some((selectedItem: Entity) => {
      return item.getId() === selectedItem.getId();
    });
  }

  public getIsReadonly(): boolean {
    return this.isReadonly;
  }
}
