import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {SisTextAreaSettingsComponent} from './sis-textarea-settings.component';

@Component({
             selector   : 'sis-textarea',
             templateUrl: 'sis-textarea.component.html',
           })
@ControlMetadata({
                   name        : 'Text area',
                   settings    : {
                     main: SisTextAreaSettingsComponent,
                   },
                   template    : `
                      <sis-textarea [id]="'%{id}'"
                      [dir]="%{dir}"
                      [(value)]="%{field}"
                      [isReadonly]="%{isReadonly}"
                      [maxLength]="%{maxLength}"
                      [hint]="%{hint}"
                      %{#textarea-rows} [rows]="%{textarea-rows}"%{/textarea-rows}
                      %{#placeholder} [placeholder]="%{placeholder}"%{/placeholder}
                      ></sis-textarea>
                  `,
                   cellCount   : 6,
                   isFieldBound: true,
                 })
export class SisTextAreaComponent extends SisControlWithSettings {
  @Input()
  public id: string;

  @Input()
  public maxLength: string;

  @Input()
  public rows = 4;

  @Input()
  public dir = 'lte';

  @Input()
  public placeholder: string;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  private _value: string | undefined;

  private _isReadonly: boolean;

  get value(): string | undefined {
    return this._value;
  }

  @Input()
  set value(value: string | undefined) {
    this._value = value;
    this.valueChange.emit(value);
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }

  @Input()
  set isReadonly(value: boolean) {
    this._isReadonly = value;
  }

  public onBlur() {
    this._value = this._value && this._value.trim();
    this.valueChange.emit(this.value);
  }

}
