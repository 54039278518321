import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisNumberSettingsComponent} from './sis-number-settings.component';
import {FormattingService} from '../../../services';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'sis-number',
    template: `
        <ng-container *ngIf="!isReadonly">
            <mat-form-field
                class="sis-form-field"
                color="accent"
                [floatLabel]="'never'"
                [hintLabel]="hint | sisTranslateMultilingual | async"
            >
                <input
                    matInput
                    [(ngModel)]="value"
                    [id]="id"
                    type="number"
                    [step]="step_num"
                    (blur)="blur()"
                    (ngModelChange)="formatAndEmitValue()"
                    [max]="max_num"
                    [min]="min_num"
                    (keypress)="onKeyPress($event)"
                />
            </mat-form-field>
        </ng-container>
        <div *ngIf="isReadonly" id="{{ id }}">
            <ng-container *ngIf="hasValue()">
                {{ formattedValue }}
            </ng-container>
            <span *ngIf="!hasValue()" class="sis-no-data">
                {{ 'de_no_data' | sisTranslate | async }}
            </span>
        </div>
    `,
})
@ControlMetadata({
    cellCount: 2,
    name: 'Number',
    nameKey: 'de.controls.number.title',
    settings: {
        main: SisNumberSettingsComponent,
    },
    template: `
        <sis-number
                [id]="'%{id}'"
                [value]="%{checkedItem}"
                [fieldName]="%{checkedItem-name}"
                (valueChange)="contextEntity.getProperty('%{checkedItem-name}').value = $event"
                [isReadonly]="%{isReadonly}"
                [hint]="%{hint}"
                [step]="%{step}"
                [max]="%{max}"
                [min]="%{min}"
                %{#maxLength} [maxLength]="%{maxLength}" %{/maxLength}>
        </sis-number>
    `,
})
export class SisNumberComponent extends SisControlWithSettings
    implements OnInit, OnDestroy {
    @Input()
    public id: string;
    @Input()
    public value: number;
    @Input()
    public maxLength: number;
    @Input()
    public fieldName: string;
    @Output()
    public valueChange: EventEmitter<number> = new EventEmitter<number>();
    public formattedValue = '';
    private _isReadonly = false;
    private _min: number = Number.NEGATIVE_INFINITY;
    private _max: number = Number.POSITIVE_INFINITY;
    private _step: number;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(private formattingService: FormattingService) {
        super();
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
        this.formatValue();
    }

    get min_num(): number {
        return this._min;
    }

    get max_num(): number {
        return this._max;
    }

    get step_num(): number {
        return this._step;
    }

    @Input()
    set min(min: string) {
        if (min) {
            this._min = +min;
        } else {
            this._min = Number.NEGATIVE_INFINITY;
        }
    }

    @Input()
    set max(max: string) {
        if (max) {
            this._max = +max;
        } else {
            this._max = Number.POSITIVE_INFINITY;
        }
    }

    @Input()
    set step(step: string) {
        if (step) {
            this._step = +step;
        } else {
            this._step = 1;
        }
    }

    public formatAndEmitValue(): void {
        this.formatValue();
        this.valueChange.emit(this.value);
    }

    public blur(): void {
        if (this.value !== undefined && this.value !== null) {
            this.value = Math.min(Math.max(this.value, this._min), this._max);
            this.formatAndEmitValue();
        }
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public ngOnInit(): void {
        this.formatAndEmitValue();
    }

    public hasValue(): boolean {
        return this.value !== null && this.value !== undefined;
    }

    public onKeyPress(evt: KeyboardEvent): void {
        if (this.value && this.value.toString().length > this.maxLength - 1) {
            evt.preventDefault();
        }
    }

    private formatValue(): void {
        if (!this.fieldName || !this._isReadonly) {
            return;
        }
        if (!this.value && this.value !== 0) {
            this.formattedValue = '';
        } else {
            this.formattingService
                .formatNumber(this.value)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    value => (this.formattedValue = value),
                    err => {
                        console.error(err);
                        this.formattedValue = '';
                    }
                );
        }
    }
}
