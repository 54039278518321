import {Component, Input} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
             selector: 'sis-pipe-msg',
             template: '{{msg | sisTranslateMessage | async}}',
           })
@ControlMetadata({
                   template: `<sis-pipe-msg [msg]="%{msg}"></sis-pipe-msg>`,
                 })
export class SisPipeMsgComponent {
  @Input()
  public msg: string | undefined;

}
