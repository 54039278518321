import {Component, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {FormattingService} from '../../services';

@Component({
             moduleId: module.id + '',
             selector: 'sis-datetime-view',
             template: `
               <span [ngClass]="{'sis-no-data': !formattedDate}" id="{{id}}"> {{formattedDate ? formattedDate : ('de_no_data' | sisTranslate | async)}} </span>
             `,
           })
@ControlMetadata({
                   template: `
                    <sis-datetime-view [date]="%{date}" %{#isDateOnly} [isDateOnly]="%{isDateOnly}" %{/isDateOnly}>
                    </sis-datetime-view>`,
                 })
export class SisDatetimeViewComponent implements OnChanges {
  @Input()
  public id: string;
  @Input()
  public date: Date;
  @Input()
  public isDateOnly = false;

  public formattedDate;

  constructor(private formattingService: FormattingService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const dateChanged: SimpleChange = changes.date;
    if (dateChanged) {
      this.updateDate();
    }
  }

  private updateDate(): void {
    if (this.date) {
      if (this.isDateOnly) {
        this.formattingService.formatDate(this.date.valueOf())
            .subscribe(date => this.formattedDate = date, console.error);
      } else {
        this.formattingService.formatDateTime(this.date.valueOf())
            .subscribe(date => this.formattedDate = date, console.error);
      }
    }
  }

}
