import {CommunicationData, CommunicationKey, CommunicationService} from '@synisys/idm-dynamic-controls-metadata';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Input, Output} from '@angular/core';
import {multilingualToString, stringToMultilingual} from './utilities';
import {SisControlWithTooltipSettings, TooltipSettings} from './tooltip';
import {TitleJson} from '@synisys/idm-dynamic-layout-interpreter';

/**
 * @deprecated - use BaseSettings from ng-controls
 */
export class SisSimpleSettings extends SisControlWithTooltipSettings {

  @Input()
  public languages: Language[];

  @Input()
  public currentLanguageId: number;

  public hint: MultilingualString;

  protected _controlLabel: TitleJson;

  constructor(protected _communicationService: CommunicationService) {
    super(_communicationService);
  }

  @Output()
  public hintChange(hint: MultilingualString): void {
    this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
      bindings: {},
      state   : {hint: multilingualToString(hint)},
    }));
  }

  @Input()
  public set state(value: object) {
    if (value['hint'] !== '') {
      this.hint = stringToMultilingual(value['hint']);
    }
    if (value['tooltipSettings']) {
      this._tooltipSettings = TooltipSettings.fromJsonString(value['tooltipSettings']);
    }
  }

  public get controlLabel(): TitleJson {
    return this._controlLabel;
  }

  @Input()
  public set controlLabel(controlLabel: TitleJson) {
    this._controlLabel = controlLabel;
    this._communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
      controlLabel
    }));
  }
}
