import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisSingleCheckboxSettingsComponent} from './sis-single-checkbox-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import './sis-single-checkbox.component.scss';

@Component({
             selector: 'sis-single-checkbox',
             templateUrl: 'sis-single-checkbox.component.html',
           })
@ControlMetadata({
                   name          : 'Single checkbox',
                   settings      : {
                     main: SisSingleCheckboxSettingsComponent,
                   },
                   template      : `
                        <sis-single-checkbox
                            [id]="'%{id}'"
                            [checked]="%{field}"
                            [isReadonly]="%{isReadonly}"
                            [hint]="%{hint}"
                            (onChange)="%{onChange}">
                        </sis-single-checkbox>
                    `,
                   isFieldBound  : true,
                   defaultActions: {
                     "onChange": "simpleChange"
                   }

                 })
export class SisSingleCheckboxComponent extends SisControlWithSettings {
  @Input()
  public id: string;

  @Input()
  public checked: boolean;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _isReadonly = false;

  get isReadonly(): boolean {
    return this._isReadonly;
  }

  @Input()
  set isReadonly(value: boolean) {
    this._isReadonly = value;
  }
}
