import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Entity, EntityBuilder} from '@synisys/idm-de-core-frontend';
import {DeService, MainEntity} from '@synisys/idm-de-service-client-js';
import {Observable} from 'rxjs/Observable';
import {first} from 'rxjs/operators';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {SisSingleSelectEntitySearchSettingsComponent} from '@synisys/idm-entity-search-client-js';

@Component({
    moduleId: module.id + '',
    selector: 'sis-dynamic-entity-select',
    templateUrl: 'sis-dynamic-entity-select.component.html',
})
@ControlMetadata({
    name: 'Single Select Entity Search',
    settings: {
        main: SisSingleSelectEntitySearchSettingsComponent,
    },
    template: `
		<sis-dynamic-entity-select
			[id]="'%{id}'"
			[value]="%{field}"
			(entityChange)="actions.simpleChange(contextEntity, '%{field-name}', $event)"
			[compoundCategory]="'%{field-category}'"
			[fieldName]="'%{field-name}'"
			[formCategory]="category"
			[nameFields]="%{filterFields}"
			[isReadonly]="%{isReadonly}">
		</sis-dynamic-entity-select>`,
    defaultActions: {
        filterFields: 'nameMetaFields-async',
    },
    isFieldBound: true,
})
export class SisDynamicEntitySelectComponent {
    @Input()
    public id: string;
    /**
     * selected value
     */
    @Input()
    public value: Entity;
    /**
     * root category of form
     */
    @Input()
    public formCategory: string;
    /**
     * one of the MAIN_ENTITY fields in {@link formCategory}
     */
    @Input()
    public fieldName: string;
    /**
     * compound category of {@link fieldName}
     */
    @Input()
    public compoundCategory: string;
    @Input()
    public isReadonly: Boolean = false;
    @Output()
    public entityChange: EventEmitter<Entity> = new EventEmitter<Entity>();

    private entityBuilder = new EntityBuilder();
    private _nameFields: string[];

    constructor(public kbService: KbService, public deService: DeService) {}

    get nameFields(): string[] {
        return this._nameFields;
    }

    @Input()
    set nameFields(value: string[]) {
        this._nameFields = value || [];
    }

    public submitEntity(entity: object): void {
        if (this.formCategory && this.fieldName) {
            this.kbService
                .getMainEntityMetaFields(this.formCategory, this.fieldName)
                .pipe(first())
                .subscribe(
                    metaFields => {
                        this.entityChange.emit(
                            this.entityBuilder.createEntity(
                                MainEntity,
                                metaFields,
                                entity
                            )
                        );
                    },
                    e => console.log(e)
                );
        }
    }

    public getEntityName(): Observable<string> {
        return this.deService.getEntityName(
            this.compoundCategory,
            this.value,
            ' '
        );
    }
}
