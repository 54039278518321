import {Component} from '@angular/core';
import {CommunicationService, ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
             moduleId: module.id + '',
             selector: 'sis-boolean-radio-settings',
             template: `
                 <sis-ng-hint-settings [(hint)]="hint" [languages]="languages"
                                       [currentLanguageId]="currentLanguageId">
                 </sis-ng-hint-settings>
                 <sis-ng-tooltip-settings [(settings)]="tooltipSettings" [languages]="languages"
                                          [currentLanguageId]="currentLanguageId">
                 </sis-ng-tooltip-settings>
             `
           })
@ControlMetadata({
                   template: `
                      <sis-boolean-radio-settings [state]="%{state}" [languages]="%{languages}"
                      [currentLanguageId]="%{currentLanguageId}"></sis-boolean-radio-settings>`,
                 })
export class SisBooleanRadioSettingsComponent extends BaseSettings {
  constructor(communicationService: CommunicationService) {
    super(communicationService);
  }
}
