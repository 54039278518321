import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisTextBoxSettingsComponent} from './sis-textbox-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {CharacterCountSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/character-counter/character-count.settings';

@Component({
    selector: 'sis-textbox',
    templateUrl: 'sis-textbox.component.html',
})
@ControlMetadata({
    name: 'Text box',
    settings: {
        main: SisTextBoxSettingsComponent,
    },
    cellCount: 6,
    template: `
                        <sis-textbox [id]="'%{id}'" [(value)]="%{field}" [placeholder]="%{placeholder}"
                                     [maxlength]="%{maxLength}" [type]="%{type}" [hint]="%{hint}"
                                     [dir]="%{dir}" [characterCountSettings]="%{characterCountSettings}"
                                     [isReadonly]="%{isReadonly}">
                        </sis-textbox>
                     `,
    isFieldBound: true,
})
export class SisTextBoxComponent extends SisControlWithSettings
    implements OnInit {
    private static readonly _numberRegExp: RegExp = new RegExp(
        '^[+,-]?[0-9]+[.]?[0-9]*$'
    );
    @Input()
    public id: string;
    @Input()
    public placeholder: string;
    @Input()
    public dir = 'ltr';
    @Input()
    public type: string;
    @Input()
    public isReadonly = false;
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();
    private _maxlength: number | undefined;
    private _value: string | undefined;
    private _showCharactersCount: boolean;

    get value(): string | undefined {
        return this._value;
    }

    @Input()
    set value(value: string | undefined) {
        this._value = value;
        this.valueChange.emit(value);
    }

    public get maxlength(): number | undefined {
        return this._maxlength;
    }

    @Input()
    public set maxlength(val: number | undefined) {
        if (val) {
            this._maxlength = val;
        } else {
            this._maxlength = 200;
        }
    }

    @Input()
    public set characterCountSettings(value: string) {
        this._showCharactersCount = CharacterCountSettings.fromJsonString(
            value
        ).showCharacterCount;
    }

    public get showCharactersCount(): boolean {
        return this._showCharactersCount;
    }

    public ngOnInit(): void {
        if (this.type !== 'number') {
            this.type = 'text';
        }
    }

    public getIsReadonly(): boolean {
        return this.isReadonly;
    }

    public onKeyPress(evt: Event): void {
        if (
            this.value &&
            this.maxlength &&
            this.value.toString().length > this.maxlength
        ) {
            evt.preventDefault();
        }
    }

    public onBlur(): void {
        this._value = this._value && this._value.trim();
        this.valueChange.emit(this.value);
    }
}
