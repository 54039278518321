import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {multilingualToString, stringToMultilingual} from '../../utilities';
import {isFinite} from 'lodash';
import {TooltipSettings} from '@synisys/idm-ng-controls';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
    moduleId: module.id + '',
    selector: 'sis-number-settings',
    template: `
        <div class="sis-form-sidebar__section" *ngIf="isReady">
            <div class="sis-form-sidebar__label">
                {{ 'de.settings.field.binding' | sisTranslate | async }}
            </div>
            <sis-combo-box
                [(value)]="selectedMetaField"
                [placeHolder]="'de_select_one' | sisTranslateMessage | async"
            >
                <sis-select-item
                    *ngFor="let item of compatibleMetaFields"
                    [value]="item"
                    [name]="item | sisTranslateDisplayName | async"
                >
                </sis-select-item>
            </sis-combo-box>
            <div
                class="sis-form-sidebar__subsection"
                [ngClass]="{'sis-error-container': !isValid()}"
            >
                <div class="sis-form-sidebar__label">
                    {{ 'de.settings.number.step' | sisTranslate | async }}
                </div>
                <sis-number
                    [min]="0"
                    [(value)]="step"
                    [isReadonly]="false"
                    [step]="0.1"
                    (valueChange)="valueChange()"
                ></sis-number>
            </div>
        </div>
        <sis-ng-hint-settings
            [(hint)]="hint"
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
        >
        </sis-ng-hint-settings>
        <sis-ng-tooltip-settings
            [(settings)]="tooltipSettings"
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
        >
        </sis-ng-tooltip-settings>
        <sis-control-label-settings
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
            [(titleModel)]="controlLabel"
        >
        </sis-control-label-settings>
    `,
})
@ControlMetadata({
    template: `
          <sis-number-settings [category]="%{category}" [fields]="%{fields}" [state]="%{state}"
                               [languages]="%{languages}" [currentLanguageId]="%{currentLanguageId}"
                               [controlLabel]="%{controlLabel}">
          </sis-number-settings>`,
})
export class SisNumberSettingsComponent extends BaseSettings
    implements OnInit, OnDestroy {
    @Input()
    public category: string;
    @Input()
    public fields: {checkedItem: MetaField | undefined};
    public step = 1;
    public compatibleMetaFields: MetaField[] = [];
    public isReady = false;
    private _selectedMetaField: MetaField | undefined = undefined;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(
        private kbService: KbService,
        protected communicationService: CommunicationService
    ) {
        super(communicationService);
    }

    @Input()
    public set state(value: {hint: string; step: number | string}) {
        if (value['hint'] !== '') {
            this.hint = stringToMultilingual(value['hint']);
        }
        if (value['tooltipSettings']) {
            this._tooltipSettings = TooltipSettings.fromJsonString(
                value['tooltipSettings']
            );
        }
        if (isFinite(value.step)) {
            this.step = <number>value.step;
        }
    }

    get selectedMetaField(): MetaField | undefined {
        return this._selectedMetaField;
    }

    set selectedMetaField(value: MetaField | undefined) {
        this._selectedMetaField = value;
        this.valueChange();
    }

    public valueChange(): void {
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                fields: {checkedItem: this._selectedMetaField},
                state: {
                    hint: multilingualToString(this.hint),
                    step: this.step,
                    tooltipSettings: this._tooltipSettings
                        ? this._tooltipSettings.toJsonString()
                        : undefined,
                },
            })
        );
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public ngOnInit(): void {
        this.kbService
            .getMetaFields(this.category)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (metaFields: MetaField[]) => {
                    this.compatibleMetaFields = metaFields.filter(
                        (metaField: MetaField) =>
                            metaField.getType() === MetaFieldType.INTEGER ||
                            metaField.getType() === MetaFieldType.DECIMAL
                    );
                    if (this.fields.checkedItem) {
                        this._selectedMetaField = this.compatibleMetaFields.find(
                            f =>
                                f.getMetaFieldId().getSystemName() ===
                                this.fields.checkedItem
                                    .getMetaFieldId()
                                    .getSystemName()
                        );
                    }
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    public isValid(): boolean {
        return !isNaN(this.step) && this.step > 0;
    }

    public hintChanged(hint: MultilingualString): void {
        this.hint = hint;
        this.valueChange();
    }
}
