import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisBooleanRadioSettingsComponent} from './sis-boolean-radio-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import './sis-boolean-radio.component.scss';

@Component({
        moduleId: module.id + '',
        selector: 'sis-boolean-radio',
        templateUrl: 'sis-boolean-radio.component.html',
})
@ControlMetadata({
      name        : 'Boolean Radio',
      settings    : {
        main: SisBooleanRadioSettingsComponent,
      },
      template    : `
            <sis-boolean-radio [id]="'%{id}'" [checked1]="%{checked1}" [checked2]="%{checked2}" (onclick1)="%{click1}"
                               (onclick2)="%{click2}"  [msg1]="%{msg1}" [msg2]="%{msg2}" [name]="%{name}"
                               [isReadonly]="%{isReadonly}" [hint]="%{hint}">
            </sis-boolean-radio>
        `,
      cellCount   : 3,
      isFieldBound: true,
})
export class SisBooleanRadioComponent extends SisControlWithSettings {
  @Input()
  public id: string;
  @Input()
  public checked1: boolean;
  @Input()
  public checked2: boolean;
  @Output()
  public onclick1: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public onclick2: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  public msg1: string;
  @Input()
  public msg2: string;
  @Input()
  public name: string;
  @Input()
  public isReadonly = false;

  public getCheckedName(): string {
    if (this.checked1) {
      return this.msg1;
    } else if (this.checked2) {
      return this.msg2;
    }
    return '';
  }

  public getIsReadonly(): boolean {
    return this.isReadonly;
  }

}
