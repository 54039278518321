import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
             selector: 'sis-doc',
             template: `
               <div (click)="onDownload.emit($event)">
                 <i class="{{getImageClass}}" aria-hidden="true" style="font-size: 24px"></i>
               </div>
             `,
           })
@ControlMetadata({
                   template: `
        <sis-doc (onDownload)="%{onDownload}" [getImageClass]="%{getImageClass}"></sis-doc>
    `,
                 })
/**
 * @deprecated - use sis-document from ng-controls
 */
export class SisDocComponent {
  @Output()
  public onDownload: EventEmitter<object> = new EventEmitter<object>();
  @Input()
  public getImageClass: string;

}
