import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    Classifier,
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {SisDynamicMultiSelectSettingsComponent} from './sis-dynamic-multiselect-settings.component';
import {Subject} from 'rxjs/Subject';
import {map, takeUntil} from 'rxjs/operators';
import './sis-dynamic-multiselect.component.scss';

import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {KbService, MetaFieldId} from '@synisys/idm-kb-service-client-js';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Component({
               moduleId   : module.id + '',
               selector   : 'sis-dynamic-multiselect',
               templateUrl: 'sis-dynamic-multiselect.component.html',
           })
@ControlMetadata({
                     name          : 'Dynamic Multiselect',
                     settings      : {
                         main: SisDynamicMultiSelectSettingsComponent,
                     },
                     template      : `
                        <sis-dynamic-multiselect [id]="'%{id}'" [entity]="contextEntity"
                            [categoryName]="'%{field-category}'" [hint]="%{hint}" [fieldSystemName]="'%{field-name}'"
                            [classifierViews]="%{dataItems}" [selectedClassifiers]="%{field}"
                            [nameMetaFieldIds]="%{nameMetaFieldIds}" [nameField]="'name'" [valueField]="'id'"
                            [isReadonly]="%{isReadonly}">
                        </sis-dynamic-multiselect>
                      `,
                     iconInfo      : 'fa-list-alt',
                     isFieldBound  : true,
                     defaultActions: {
                         dataItems       : 'loadClassifiers-async',
                         nameMetaFieldIds: 'getNameMetaFieldIds-async',
                     },
                 })
export class SisDynamicMultiselectComponent extends SisControlWithSettings
    implements OnInit, OnDestroy {
    @Input()
    public id: string;
    @Input()
    public entity: Entity;

    @Input()
    public fieldSystemName: string;

    @Input()
    public categoryName: string;


    public isReady$: Observable<boolean>;

    private _isReadonly: boolean;
    private _selectedClassifiers: Classifier[];
    private _nameMetaFieldIds: MetaFieldId[];
    private _dataItems: DataItem[];
    private destroySubject$: Subject<void> = new Subject<void>();
    private dataItemsSubject$: ReplaySubject<DataItem[]> = new ReplaySubject<DataItem[]>(1);
    private _nameMetaFieldIdsSubject: ReplaySubject<MetaFieldId[]> = new ReplaySubject<MetaFieldId[]>(1);

    private readonly _nameField = 'name';
    private readonly _valueField = 'id';

    constructor(
        private _classifierService: ClassifierService,
        private _currentLanguageProvider: CurrentLanguageProvider,
        private _kbService: KbService
    ) {
        super();
    }

    get nameField(): string {
        return this._nameField;
    }

    @Input()
    set nameField(value: string) {
        if (value !== 'name') {
            throw new Error(`nameField should be 'name'`);
        }
    }

    get valueField(): string {
        return this._valueField;
    }

    @Input()
    set valueField(value: string) {
        if (value !== 'id') {
            throw new Error(`nameField should be 'id'`);
        }
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    @Input()
    set classifierViews(value: ClassifierView[]) {
        if (!this._dataItems || this._dataItems.length === 0) {
            this._dataItems = value ? this.convertToDataItems(value) : [];
        } else if (value) {
            this.addDataItems(...this.convertToDataItems(value));
        }
        if (value) {
            this.dataItemsSubject$.next(this._dataItems);
        }
    }

    get dataItems(): DataItem[] {
        return this._dataItems;
    }

    @Input()
    public set selectedClassifiers(values: Classifier[]) {
        this._selectedClassifiers = values ? values : [];
    }

    get nameMetaFieldIds(): MetaFieldId[] {
        return this._nameMetaFieldIds;
    }

    @Input()
    set nameMetaFieldIds(value: MetaFieldId[]) {
        this._nameMetaFieldIds = value ? value : [];
        if (value) {
            this._nameMetaFieldIdsSubject.next(this._nameMetaFieldIds);
        }
    }

    public ngOnInit(): void {
        this.isReady$ = combineLatest(
            this.dataItemsSubject$,
            this._nameMetaFieldIdsSubject,
            this._currentLanguageProvider.getCurrentLanguage()
        ).pipe(
            map((data: [DataItem[], MetaFieldId[], Language]) => {
                this._selectedClassifiers
                    .filter((classifier: Classifier) =>
                                this._dataItems.every(
                                    (dataItem: DataItem) =>
                                        dataItem.id !== classifier.getId()
                                )
                    )
                    .forEach((classifier: Classifier) => {
                        const name = this._nameMetaFieldIds
                                         .map((nameMetaFieldId: MetaFieldId) => {
                                             const value = classifier.getProperty(
                                                 nameMetaFieldId.getSystemName()
                                             ).value;
                                             if (value instanceof MultilingualString) {
                                                 return value.getValue(data[2].getId());
                                             }
                                             return value;
                                         })
                                         .join('/');
                        this.addDataItems({
                                              id       : classifier.getId(),
                                              name     : name,
                                              isEnabled: false,
                                          });
                    });
                return true;
            }),
            takeUntil(this.destroySubject$)
        );
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public convertToIds(): number[] {
        return this.entity
               ? this.entity
                     .getProperty<Classifier[]>(this.fieldSystemName)
                     .value.map(item => item.getId())
               : [];
    }

    public convertToClassifiers(values: number[]) {
        if (values.length === 0) {
            this.entity.getProperty<Classifier[]>(
                this.fieldSystemName
            ).value = [];
        } else {
            this._classifierService
                .loadClassifiersByIds(this.categoryName, values)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    data => {
                        this._selectedClassifiers = data.getData();
                        this.entity.getProperty<Classifier[]>(
                            this.fieldSystemName
                        ).value = data.getData();
                    },
                    err => console.error(err)
                );
        }
    }

    private convertToDataItems(classifierViews: ClassifierView[]): DataItem[] {
        return classifierViews.map((classifierView: ClassifierView) => ({
            id       : classifierView.getId(),
            name     : classifierView.getName(),
            isEnabled: true,
        }));
    }

    private addDataItems(...dataItems: DataItem[]) {
        dataItems.forEach((dataItem: DataItem) => {
            if (
                this._dataItems.findIndex(
                    (item: DataItem) => item.id === dataItem.id
                ) === -1
            ) {
                this._dataItems.push(dataItem);
            }
        });
    }
}

export interface DataItem {
    id: number;
    name: string;
    isEnabled: boolean;
}
