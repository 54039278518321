import {Input} from '@angular/core';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {stringToMultilingual} from './utilities';
import {entries, isPlainObject, parseInt} from 'lodash';
import {MultilingualStringDto} from '@synisys/skynet-store-utilities';

export class SisControlWithSettings {
    private _hint: MultilingualString | MultilingualStringDto | undefined;

    get hint():
        | MultilingualString
        | MultilingualStringDto
        | string
        | undefined {
        return this._hint;
    }

    @Input()
    set hint(
        multilingualString:
            | MultilingualString
            | MultilingualStringDto
            | string
            | undefined
    ) {
        if (multilingualString instanceof MultilingualString) {
            this._hint = multilingualString;
        } else if (isPlainObject(multilingualString)) {
            const builder = MultilingualString.newBuilder();
            entries(multilingualString).forEach(([key, value]) => {
                builder.withValueForLanguage(parseInt(key, 10), value);
            });
            this._hint = builder.build();
        } else if (multilingualString) {
            this._hint = stringToMultilingual(multilingualString as string);
        }
    }
}
