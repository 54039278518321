import {Component, Input, OnInit} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {DocumentInfo, DocumentService, FileType} from '@synisys/idm-document-service-client-js';
import {DeService} from '@synisys/idm-de-service-client-js';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {zip} from 'rxjs/observable/zip';
import {mergeMap, takeUntil} from 'rxjs/operators';

@Component({
             moduleId: module.id + '',
             selector: 'sis-document-download',
             template: `
               <ng-container *ngIf="isReady">
                 <a (click)="download()">
                   {{documentName}}
                 </a>
               </ng-container>
             `,
           })
@ControlMetadata({
                   name        : 'Document',
                   template    : `
                      <sis-document-download [id]="%{id}" [documentId]="%{field}" [fileType]="%{fileType}"
                       [isTemporary]="%{isTemporary}">
                      </sis-document-download>
                  `,
                   isFieldBound: false,
                 })
/**
 * @deprecated - use sis-document from ng-controls
 */
export class SisDocumentDownloadComponent extends AbstractDestructionSubject implements OnInit {
  @Input()
  public documentId: number;
  @Input()
  public categoryName: string;
  @Input()
  public rootFieldName: string;
  @Input()
  public entityInstanceId: number;
  @Input()
  public isTemporary = false;

  @Input()
  set fileType(value: FileType) {
    if (!value) {
      value = FileType.ORIGINAL;
    }
    this._fileType = value;
  }

  get fileType(): FileType {
    return this._fileType;
  }

  @Input()
  public documentName: string;

  public url: string;
  public isReady = false;

  private _fileType = FileType.ORIGINAL;

  constructor(private documentService: DocumentService,
              private deService: DeService) {
    super();
  }

  public ngOnInit() {
    if (this.isTemporary) {
      this.isReady = true;
      return;
    }
    zip(this.documentService.getDocumentInfo(this.documentId),
        this.deService.getDocumentToken(this.categoryName, this.entityInstanceId, this.rootFieldName))
      .pipe(mergeMap((data: [DocumentInfo, string]) => {
              this.documentName = data[0].name;
              return this.documentService.getDownloadUrl(this.documentId, data[1]);
            }),
            takeUntil(this.destroySubject$))
      .subscribe(url => {
        this.url = url;
        this.isReady = true;
      }, err => console.error(err));
  }

  public download(): void {
    if (this.isTemporary) {
      this.documentService.downloadTempDocument(this.documentId);
    }
    this.documentService.downloadDocument(this.url, this.fileType);
  }
}
