import {first, map, mergeMap} from 'rxjs/operators';
import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {FormattingService} from '../../../services';
import {SisIntegerSettingsComponent} from './sis-integer-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {of} from 'rxjs/observable/of';

@Component({
             selector   : 'sis-integer',
             templateUrl: 'sis-integer.component.html',
           })
@ControlMetadata({
                   name        : 'Integer',
                   settings    : {
                     main: SisIntegerSettingsComponent,
                   },
                   template    : `
                        <sis-integer [id]="'%{id}'" [(value)]="%{field}" [isReadonly]="%{isReadonly}" [hint]="%{hint}"
                        [max]="%{max}" [min]="%{min}" [maxLength]="%{maxLength}" [toFormat]="%{toFormat}">
                        </sis-integer>
                   `,
                   cellCount   : 3,
                   isFieldBound: true,
                 })
export class SisIntegerComponent extends SisControlWithSettings {

  @Input()
  public id: string;

  @Input()
  public isReadonly = false;

  @Input()
  public toFormat: boolean = true;
  //
  @Output()
  public valueChange: EventEmitter<number> = new EventEmitter<number>();
  private _value: string | undefined;
  private _min: number = Number.MIN_SAFE_INTEGER;
  private _max: number = Number.MAX_SAFE_INTEGER;
  private _maxLengthAfterFormat: number;
  private _maxLength: number;

  constructor(private formattingService: FormattingService) {
    super();
  }

  @Input()
  public set maxLength(val: number | undefined) {
    if (val !== null && val !== undefined) {
      this._maxLength = val;
      this._maxLengthAfterFormat = val;
    } else {
      this._maxLength = 10;
      this._maxLengthAfterFormat = 10;
    }
  }

  @Input()
  set max(val: string | undefined) {
    if (val !== undefined && val !== '') {
      this._max = +val;
    } else {
      this._max = Number.MAX_SAFE_INTEGER;
    }
  }

  @Input()
  set min(val: string | undefined) {
    if (val !== undefined && val !== '') {
      this._min = +val;
    } else {
      this._min = Number.MIN_SAFE_INTEGER;
    }
  }

  public get value(): string | number | undefined {
    return this._value;
  }

  @Input()
  public set value(value: string | number | undefined) {
    if (value || value === 0) {
      this._value = value + '';
      this.checkValue(true, this.toFormat);
    } else {
      this._value = '';
      this.valueChange.emit(null);
    }
  }

  public handleKeyPress(evt: KeyboardEvent) {
    if (evt.which < 48 || evt.which > 57) {
      const eventTarget: HTMLInputElement = <HTMLInputElement>evt.target;
      const selection = this._value.substring(eventTarget.selectionStart, eventTarget.selectionEnd);
      if (!((evt.which === 45 && eventTarget.selectionStart === 0 &&
        (this._value.indexOf('-') === -1 || selection.indexOf('-') !== -1)))) {
        evt.preventDefault();
      }
    }
  }

  public get maxLength(): number {
    return this._maxLength;
  }

  get maxLengthAfterFormat(): number {
    return this._maxLengthAfterFormat;
  }

  public getIsReadonly(): boolean {
    return this.isReadonly;
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  private checkValue(emit: boolean, format: boolean): void {
    const nonNumericalSymbols: number = this._value.length - this._value.replace(/[^0-9]/g, '').length;

    this._maxLengthAfterFormat = this._maxLength + nonNumericalSymbols;
    if (this._value === '-') {
      return;
    }
    if (this._value === '') {
      this.valueChange.emit(null);
      return;
    }
    this.formattingService.parseNumber(this._value)
        .pipe(
          mergeMap(number => {
            if (isNaN(number)) {
              return of(number);
            } else {
              const parsedValue = Math.trunc(Math.min(Math.max(number, this._min), this._max));
              if (format) {
                return this.formattingService.formatNumber(parsedValue).pipe(map(formatted => {
                  this._value = formatted;
                  this._maxLengthAfterFormat = this._maxLength + formatted.length - parsedValue.toString().length;
                  return parsedValue;
                }));
              } else {
                return of(parsedValue);
              }
            }
          }),
          first(),
        ).subscribe(number => {
      if (isNaN(number)) {
        this.valueChange.emit(null);
        this._value = '';
        return;
      }
      if (emit) {
        this.valueChange.emit(number);
      }
    }, console.error);
  }

}
