import {Component} from '@angular/core';
import {
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
    moduleId: module.id + '',
    selector: 'sis-text-box-settings',
    templateUrl: 'sis-textbox-settings.component.html',
})
@ControlMetadata({
    template: `
                      <sis-text-box-settings [state]="%{state}" [languages]="%{languages}"
                      [currentLanguageId]="%{currentLanguageId}" [controlLabel]="%{controlLabel}">
                      </sis-text-box-settings>`,
})
export class SisTextBoxSettingsComponent extends BaseSettings {
    constructor(communicationService: CommunicationService) {
        super(communicationService);
    }
}
