import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {SisDateTimeSettingsComponent} from './sis-date-time-settings.component';
import './sis-date-time-picker.component.scss';
import {FormattingService} from '../../../services';
import {
    distinctUntilChanged,
    map,
    mapTo,
    publishReplay,
    refCount,
    tap,
} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {isEqual, isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {LocaleInfo} from '@synisys/idm-locale-service-client-js';

export type TimeType = {
    hour: number; // 0 to 23
    minute: number; // 0 to 59
    seconds: number;
};
export type DateTimeType = {
    date: Date | undefined;
    time: string | undefined;
};

@Component({
    selector: 'sis-date-time-picker',
    templateUrl: 'sis-date-time-picker.component.html',
})
@ControlMetadata({
    name: 'Date time picker',
    settings: {
        main: SisDateTimeSettingsComponent,
    },
    template: `<sis-date-time-picker
                        [id]="'%{id}'"
                        [dateValue]="%{field}"
                        [hint]="%{hint}"
                        [isReadonly]="%{isReadonly}"
                        (valueChange)="actions.simpleChange(contextEntity, '%{field-name}', $event)">
               </sis-date-time-picker>`,
    cellCount: 4,
    isFieldBound: true,
})
export class SisDateTimePickerComponent extends SisControlWithSettings
    implements OnInit {
    @Input()
    public id = '';
    public seleniumTestClass = '';
    @Output()
    public valueChange = new EventEmitter<Date>();
    public isReady$: Observable<boolean>;
    // TODO: replace this when specialized message is added for de
    public readonly datePlaceholderKey =
        'portfolio.filter.date.range.choose.date';

    private _isReadonly: boolean;

    private _date: Date = undefined;
    private _time: string = undefined;
    private _localeInfo: LocaleInfo = undefined;

    private _dateTime$ = new ReplaySubject<Date | undefined>(1);
    private _emitValue$ = new ReplaySubject<DateTimeType>(1);
    private localeInfo$: Observable<LocaleInfo>;

    constructor(private readonly formattingService: FormattingService) {
        super();
    }

    get dateTime$(): ReplaySubject<Date | undefined> {
        return this._dateTime$;
    }

    get date(): Date {
        return this._date;
    }

    set date(value: Date) {
        this._date = value;
        this.emitDateTime();
    }

    get time(): string {
        return this._time;
    }

    set time(value: string) {
        this._time = value;
        this.emitDateTime();
    }

    get localeInfo(): LocaleInfo {
        return this._localeInfo;
    }

    set localeInfo(value: LocaleInfo) {
        this._localeInfo = value;
    }

    @Input()
    set dateValue(value: Date | undefined) {
        this._dateTime$.next(value);
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    public ngOnInit(): void {
        this.localeInfo$ = this.formattingService.getLocaleInfo().pipe(
            map(localeInfo => {
                this.localeInfo = localeInfo;
                return localeInfo;
            }),
            publishReplay<LocaleInfo>(1),
            refCount()
        );

        const valueSetter$ = combineLatest(
            this._dateTime$,
            this.localeInfo$
        ).pipe(
            tap(([dateTime, localeInfo]) => {
                if (isNil(dateTime)) {
                    this.emitDateTime();
                    return;
                }
                this._date = dateTime;
                this._time = timeToString(
                    {
                        hour: this._date.getHours(),
                        minute: this._date.getMinutes(),
                        seconds: this._date.getSeconds(),
                    },
                    localeInfo.timePattern
                );
                this.emitDateTime();
            })
        );
        const emitter$ = this._emitValue$.pipe(
            distinctUntilChanged((a, b) => isEqual(a, b)),
            tap((dateTime: DateTimeType) => {
                if (isNil(dateTime.time) || isNil(dateTime.date)) {
                    // tslint:disable-next-line:no-null-keyword
                    this.valueChange.emit(null);
                    return;
                }
                const time = stringToTime(dateTime.time);
                this.valueChange.emit(
                    new Date(
                        dateTime.date.getFullYear(),
                        dateTime.date.getMonth(),
                        dateTime.date.getDate(),
                        time.hour,
                        time.minute,
                        time.seconds
                    )
                );
            })
        );

        this.isReady$ = combineLatest(valueSetter$, emitter$).pipe(mapTo(true));
    }

    private emitDateTime(): void {
        this._emitValue$.next({date: this._date, time: this._time});
    }
}

function stringToTime(time: string): TimeType {
    const result: TimeType = {hour: 0, minute: 0, seconds: 0};
    const split = time.split(':');

    if (time.endsWith('AM')) {
        if (parseFloat(split[0]) !== 12) {
            result.hour = parseFloat(split[0]);
        }
    } else if (time.endsWith('PM')) {
        result.hour =
            parseFloat(split[0]) + (parseFloat(split[0]) === 12 ? 0 : 12);
    } else {
        result.hour = parseFloat(split[0]);
    }
    result.minute = parseFloat(split[1]);
    return result;
}

function timeToString(timeValue: TimeType, formatting: string): string {
    if (!formatting.includes('A')) {
        return `${twoDigits(timeValue.hour)}:${twoDigits(timeValue.minute)}`;
    }
    return `${
        timeValue.hour === 12 || timeValue.hour === 0
            ? 12
            : twoDigits(timeValue.hour % 12)
    }:${twoDigits(timeValue.minute)} ${timeValue.hour >= 12 ? 'PM' : 'AM'}`;
}

function twoDigits(value: number): string {
    return (value < 10 ? '0' : '') + value;
}
