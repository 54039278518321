import {Component, Input} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
             selector: 'sis-caption',
             template: `<h2 class="sis-form__title">{{caption}}</h2>`,
           })
@ControlMetadata({
                   template: `
        <sis-caption [caption]="%{caption}"> </sis-caption>
    `,
                 })
export class SisCaptionComponent {
  @Input()
  public caption: string;
}
