import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {MultilingualComponent} from '@synisys/idm-ng-controls/app/controls/multilingual-component/sis-multilingual.component';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {takeUntil} from 'rxjs/operators';
import {zip} from 'rxjs/observable/zip';
import './sis-hint-settings.component.scss';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-hint-setting',
             templateUrl: 'sis-hint-settings.component.html',
           })
/**
 * @deprecated - use sis-ng-hint-settings from ng-controls
 */
export class SisHintSettingsComponent extends AbstractDestructionSubject implements OnInit, OnDestroy {

  @Output()
  public valueChange: EventEmitter<MultilingualString> = new EventEmitter<MultilingualString>();

  public languages: Language[];
  public currentLanguageId: number;

  public isReady = false;
  private _hint: MultilingualString;

  get hint(): MultilingualString {
    return this._hint;
  }

  @Input()
  set hint(hint: MultilingualString) {
    this._hint = hint;
    this.valueChange.emit(hint);
  }

  @ViewChild('hintInput')
  private hintInput: MultilingualComponent;

  constructor(public languageService: LanguageService,
              public currentLanguageProvider: CurrentLanguageProvider) {
    super();
  }

  public ngOnInit(): void {
    zip(this.languageService.getInputLanguages(), this.currentLanguageProvider.getCurrentLanguage())
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: [Language[], Language]) => {
        this.languages = data[0];
        this.currentLanguageId = data[1].getId();

        if (!this._hint) {
          this.hint = MultilingualString.newBuilder()
                                         .withValueForLanguages(
                                          this.languages.map(language => language.getId()), '')
                                         .build();
        }
        this.isReady = true;
      }, err => console.error(err));
  }

}
