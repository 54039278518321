import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {FormattingService} from '../../../services';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import {SisDecimalSettingsComponent} from './sis-decimal-settings.component';
import {Subject} from 'rxjs/Subject';
import {of} from 'rxjs/observable/of';
import './sis-decimal.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'sis-decimal',
    template: `
        <ng-container *ngIf="!getIsReadonly()">
            <mat-form-field
                class="sis-form-field"
                color="accent"
                [floatLabel]="'never'"
                [hintLabel]="_hint | sisTranslateMultilingual | async"
            >
                <input
                    class="sis-decimal-input"
                    matInput
                    [(ngModel)]="_value"
                    [id]="id"
                    [maxlength]="maxLength"
                    (blur)="checkValue(false, toFormat)"
                    (keypress)="handleKeyPress($event)"
                    (keyup)="checkValue(true, false)"
                />
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="getIsReadonly()">
            <span id="{{ id }}" *ngIf="value"> {{ value }} </span>
            <span id="{{ id }}" *ngIf="!value" class="sis-no-data">
                {{ 'de_no_data' | sisTranslate | async }}
            </span>
        </ng-container>
    `,
})
@ControlMetadata({
    name: 'Decimal',
    settings: {
        main: SisDecimalSettingsComponent,
    },
    template: `
                        <sis-decimal [id]="'%{id}'" [(value)]="%{field}" [isReadonly]="%{isReadonly}" [hint]="%{hint}"
                        [max]="%{max}" [min]="%{min}"
                        [maxLength]="%{maxLength}"
                        [toFormat]="%{toFormat}">
                        </sis-decimal>
                   `,
    cellCount: 3,
    isFieldBound: true,
})
export class SisDecimalComponent extends SisControlWithSettings
    implements OnDestroy {
    @Input()
    public id: string;

    @Input()
    public isReadonly = false;

    @Input()
    public set maxLength(val: string) {
        this._maxLength = val ? (+val > 0 ? val : '15') : '15';
    }

    public get maxLength(): string {
        return this._maxLength;
    }

    @Input()
    public toFormat: boolean = true;
    @Output()
    public valueChange: EventEmitter<number> = new EventEmitter<number>();
    private _value: string;
    private _maxLength: string;
    private _min: number = Number.NEGATIVE_INFINITY;
    private _max: number = Number.POSITIVE_INFINITY;
    private destroySubject$: Subject<void> = new Subject<void>();

    constructor(private formattingService: FormattingService) {
        super();
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    @Input()
    set max(val: string | undefined) {
        if (val && val !== '') {
            this._max = +val;
        } else {
            this._max = Number.POSITIVE_INFINITY;
        }
    }

    @Input()
    set min(val: string | undefined) {
        if (val && val !== '') {
            this._min = +val;
        } else {
            this._min = Number.NEGATIVE_INFINITY;
        }
    }

    public get value(): string | number | undefined {
        return this._value;
    }

    @Input()
    public set value(value: string | number | undefined) {
        if (value || value === 0) {
            this._value = value + '';
            this.checkValue(true, this.toFormat);
        } else {
            this.valueChange.emit(null);
            this._value = '';
        }
    }

    public handleKeyPress(evt: KeyboardEvent) {
        if (evt.which < 48 || evt.which > 57) {
            const eventTarget: HTMLInputElement = <HTMLInputElement>evt.target;
            const selection = this._value.substring(
                eventTarget.selectionStart,
                eventTarget.selectionEnd
            );
            if (
                !(
                    (evt.which === 45 &&
                        eventTarget.selectionStart === 0 &&
                        (this._value.indexOf('-') === -1 ||
                            selection.indexOf('-') !== -1)) ||
                    (evt.which === 46 &&
                        (this._value.indexOf('.') === -1 ||
                            selection.indexOf('.') !== -1))
                )
            ) {
                evt.preventDefault();
            }
        }
    }

    public getIsReadonly(): boolean {
        return this.isReadonly;
    }

    private checkValue(emit: boolean, format: boolean): void {
        if (this._value === '-') {
            return;
        }
        if (this._value === '') {
            this.valueChange.emit(null);
            return;
        }
        this.formattingService
            .parseNumber(this._value)
            .pipe(
                mergeMap(number => {
                    if (isNaN(number)) {
                        return of(number);
                    } else {
                        const parsedValue = Math.min(
                            Math.max(number, this._min),
                            this._max
                        );
                        if (format) {
                            return this.formattingService
                                .formatNumber(parsedValue)
                                .pipe(
                                    map(formatted => {
                                        this._value = formatted;
                                        return parsedValue;
                                    })
                                );
                        } else {
                            return of(parsedValue);
                        }
                    }
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(number => {
                if (isNaN(number)) {
                    this.valueChange.emit(null);
                    this._value = '';
                    return;
                }
                if (emit) {
                    this.valueChange.emit(number);
                }
            }, console.error);
    }
}
