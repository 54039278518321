import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisSimpleComboSettingsComponent} from './sis-simple-combo-settings.component';
import {SisControlWithSettings} from '../../sis-control-with-settings';
import './sis-simple-combo.component.scss';

export interface EntityWithId {
  getId(): number;
}

@ControlMetadata({
                   name          : 'Simple combo',
                   settings      : {
                     main: SisSimpleComboSettingsComponent,
                   },
                   cellCount     : 3,
                   template      : `
                        <sis-simple-combo [id]="'%{id}'" [value]="%{field}"[hint]="%{hint}"
                        (onChange)="%{onChange}"
                        [items]="%{items}"
                         %{#placeholderKey} [placeholderKey]="%{placeholderKey}"%{/placeholderKey}>
                        </sis-simple-combo>
                     `,
                   isFieldBound  : true,
                   defaultActions: {
                     'items': 'loadClassifiers',
                     'onChange' : 'onClassifierChange'
                   }

                 })
@Component({
             selector: 'sis-simple-combo',
             templateUrl: 'sis-simple-combo.component.html',
           })
export class SisSimpleCombo extends SisControlWithSettings {

  @Input()
  public id: string;

  @Input()
  public value: EntityWithId | undefined;

  @Input()
  public placeholderKey = 'de_select_one';

  @Output()
  public onChange: EventEmitter<object> = new EventEmitter<object>();

  @Input()
  public items: object[];

  public isRemoveActive() {
    return this.value;
  }

  public delete(event: any): void {
    this.value = null;
    event.stopPropagation();
    this.onChange.emit(this.value);
  }

}
