import {Component} from '@angular/core';
import {
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
    moduleId: module.id + '',
    selector: 'sis-date-time-settings',
    template: `
        <sis-control-label-settings
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
            [(titleModel)]="controlLabel"
        >
        </sis-control-label-settings>
        <sis-ng-hint-settings
            [(hint)]="hint"
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
        >
        </sis-ng-hint-settings>
        <sis-ng-tooltip-settings
            [(settings)]="tooltipSettings"
            [languages]="languages"
            [currentLanguageId]="currentLanguageId"
        >
        </sis-ng-tooltip-settings>
    `,
})
@ControlMetadata({
    template: `
                      <sis-date-time-settings [state]="%{state}" [currentLanguageId]="%{currentLanguageId}"
                                              [languages]="%{languages}" [controlLabel]="%{controlLabel}">
                      </sis-date-time-settings>`,
})
export class SisDateTimeSettingsComponent extends BaseSettings {
    constructor(communicationService: CommunicationService) {
        super(communicationService);
    }
}
