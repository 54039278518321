/**
 * @author Vahagn Lazyan.
 * @since 1.3.0
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';
import {FormActions} from '../../../models';
import {isNil} from 'lodash';
import {FormService} from '@synisys/idm-dynamic-layout-interpreter';
import {catchError, first, mapTo, tap} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {Observable} from 'rxjs/Observable';
import {noop} from 'rxjs/util/noop';

@Component({
    moduleId: module.id + '',
    selector: 'sis-wf-button',
    templateUrl: 'sis-wf-button.component.html',
})
export class SisWfButtonComponent {
    @Input()
    public id: string;

    @Input()
    public workflowActions: ActionDto[] = [];

    @Input()
    public form: object;

    @Input()
    public formActions: FormActions;

    @Input()
    public category: string;

    @Input()
    public currentLanguageId: number;

    @Output()
    public onSelect: EventEmitter<number> = new EventEmitter<number>();

    private _labelMessage: string;

    constructor(private formService: FormService) {}

    @Input()
    set labelMessage(value: string) {
        if (value) {
            this._labelMessage = value;
            return;
        }
        this._labelMessage = 'de_actions';
    }

    get labelMessage(): string {
        return this._labelMessage;
    }

    public selectAction(action: ActionDto): void {
        if (isNil(action.getFormId())) {
            this.onSelect.next(action.getId());
            return;
        }
        this.formService
            .loadForm('', action.getFormId(), this.category)
            .pipe(
                mapTo(action.getFormId()),
                catchError(err => {
                    if (err.status === 404) {
                        console.warn(
                            `form with id ${action.getFormId()} is deleted`
                        );
                        return of<undefined>(undefined);
                    }
                    return Observable.throw(err);
                }),
                tap(formId => {
                    if (isNil(formId)) {
                        this.onSelect.next(action.getId());
                        return;
                    }
                    this.formActions.openWorkflowPopup(
                        this.form,
                        this.category,
                        '',
                        formId,
                        action.getId()
                    );
                }),
                first()
            )
            .subscribe(noop, console.error);
    }
}
