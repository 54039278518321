import {Component, Input} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';

@Component({
             selector: 'sis-simple-text',
             template: `
               {{value}}
             `,
           })
@ControlMetadata({
                   template : `<sis-simple-text [value]="%{field}"></sis-simple-text>`,
                   cellCount: 6,
                 })
export class SisSimpleTextComponent {
  @Input()
  public value: string | undefined;

}
