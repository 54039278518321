import {Component} from '@angular/core';
import {CommunicationService, ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
             moduleId: module.id + '',
             selector: 'sis-simple-combo-settings',
             template: `
                 <sis-ng-hint-settings [(hint)]="hint" [languages]="languages"
                                       [currentLanguageId]="currentLanguageId">
                 </sis-ng-hint-settings>
                 <sis-ng-tooltip-settings [(settings)]="tooltipSettings" [languages]="languages"
                                          [currentLanguageId]="currentLanguageId">
                 </sis-ng-tooltip-settings>
             `
           })
@ControlMetadata({
                   template: `
                      <sis-simple-combo-settings [state]="%{state}" [controlLabel]="%{controlLabel}"></sis-simple-combo-settings>`,
                 })
export class SisSimpleComboSettingsComponent extends BaseSettings {
  constructor(communicationService: CommunicationService) {
    super(communicationService);
  }
}
