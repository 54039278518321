import {first} from 'rxjs/operators';
/**
 * @author Vahagn Lazyan.
 * @since 2.4.0
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {
  CommunicationData,
  CommunicationKey,
  CommunicationService,
  ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {KbService, MetaField, MetaFieldType, MetaGroup} from '@synisys/idm-kb-service-client-js';

import {Map} from 'immutable';

import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {multilingualToString} from '../../utilities';
import {CompatibleMetaField} from '../../hierarchical-select/hierarchical-select-settings/model';
import {Subject} from 'rxjs/Subject';
import {zip} from 'rxjs/observable/zip';
import './sis-radio-settings.component.scss';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';

@Component({
             moduleId   : module.id + '',
             selector   : 'sis-radio-settings',
             templateUrl: 'sis-radio-settings.component.html',
           })
@ControlMetadata({
                   template: `<sis-radio-settings [category]="%{category}" [fields]="%{fields}" [state]="%{state}"
                                                  [languages]="%{languages}" [currentLanguageId]="%{currentLanguageId}"
                                                  [controlLabel]="%{controlLabel}">
                              </sis-radio-settings>`,
                 })
export class SisRadioSettingsComponent extends BaseSettings implements OnInit, OnDestroy {

  private static isFieldCompatible(metaField: MetaField): boolean {
    return metaField.getType() === MetaFieldType.CLASSIFIER && !metaField.getIsSystemField();
  }

  private static getBindings(): object {
    return {
      click     : 'actions.nop()',
      isReadonly: false,
    };
  }

  @Input()
  public category: string;
  public compatibleMetaFields: Map<string, CompatibleMetaField> = Map();
  public isReady = false;
  private _selectedMetaField: string | undefined = undefined;
  private readonly defaultMetaGroup: MetaGroup = new MetaGroup('', 'de.default.meta.group', []);
  private destroySubject$: Subject<void> = new Subject<void>();

  constructor(private kbService: KbService, protected communicationService: CommunicationService) {
    super(communicationService);
  }

  @Input()
  set fields(fields: { checkedItem: MetaField | undefined }) {
    this._selectedMetaField =
      fields.checkedItem ? fields.checkedItem.getMetaFieldId().getSystemName() : undefined;
  }

  get selectedMetaField(): string | undefined {
    return this._selectedMetaField;
  }

  set selectedMetaField(metaField: string | undefined) {
    this._selectedMetaField = metaField ? metaField : undefined;
    this.valueChange();
  }

  public valueChange(): void {
    this.communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
      bindings: SisRadioSettingsComponent.getBindings(),
      state   : this.getState(),
      fields  : {
        checkedItem: this._selectedMetaField ?
                     this.compatibleMetaFields.has(this._selectedMetaField) ?
                     (<CompatibleMetaField>this.compatibleMetaFields.get(this._selectedMetaField)).metaField :
                     undefined :
                     undefined,

      },
    }));
  }

  public ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  public ngOnInit(): void {
    const metaGroups$ = this.kbService.getNonSystemMetaGroups(this.category).pipe(first());
    const metaFields$ = this.kbService.getMetaFields(this.category).pipe(first());
    zip<MetaGroup[], MetaField[]>(metaGroups$, metaFields$)
      .pipe(first())
      .subscribe(data => {
        const metaFields: MetaField[] = data[1];
        const metaGroups: MetaGroup[] = data[0];
        this.compatibleMetaFields = this.compatibleMetaFields.withMutations(mutableCompatibleMetaFields => {
          metaFields.filter(SisRadioSettingsComponent.isFieldCompatible).forEach(
            (metaField: MetaField) => {
              const metaGroup: MetaGroup | undefined = metaGroups.find((group: MetaGroup) => group.getSystemName() ===
                metaField.getMetaFieldId().getMetaGroupId().getSystemName());
              mutableCompatibleMetaFields.set(metaField.getSystemName(), {
                metaField,
                metaGroup: metaGroup || this.defaultMetaGroup,
              });
            });
        });
        this.isReady = true;
      }, err => console.error(err));
  }

  public hintChanged(hint: MultilingualString): void {
    this.hint = hint;
    this.valueChange();
  }

  private getState(): { hint: string, tooltipSettings: string } {
    return {
      hint           : multilingualToString(this.hint),
      tooltipSettings: this._tooltipSettings ?
                       this._tooltipSettings.toJsonString() : undefined
    };
  }

}
